import React from "react";
import { Box, Typography } from "@material-ui/core";

const Stepper = ({ steps, currentStep }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      py={4}
      mb={2}
      bgcolor="white"
    >
      {steps.map((step, index) => {
        // Determine the styles based on the current step
        const isCompleted = index < currentStep;
        const isActive = index === currentStep;
        const backgroundColor = isActive
          ? "#01D4A1"
          : isCompleted
          ? "#092B4F"
          : "#E6E6E6";
        const textColor = isActive || isCompleted ? "#FFF" : "#000";

        return (
          <React.Fragment key={index}>
            <Box display="flex" alignItems="center">
              <Box
                bgcolor={backgroundColor}
                color={textColor}
                width={30}
                height={30}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                mr={1}
              >
                {index + 1}
              </Box>
              <Typography>{step}</Typography>
            </Box>
            {index < steps.length - 1 && (
              <Box mx={2} width={40} borderTop="1px solid #E0E0E0" />
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default Stepper;
