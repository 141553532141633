import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Icon, Logo } from "../components";
import Icons from "../constants/icons";
import Stepper from "../components/Stepper";
import PropertyInfo from "../components/CustomForm/PropertyInfo";
import TenantPayment from "../components/CustomForm/TenantPayment";
import TenantRegister from "../components/CustomForm/TenantRegister";
import { toast } from "react-toastify";
import {
  createPropertyLease,
  getPropertyAdminByName,
  sendPaymentOTP,
  verifyPaymentOTP,
} from "../services/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function CompanyPaymentPage({setCompanyPage, shortName}) {
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [leaseRegisterModal, setLeaseRegisterModal] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [propertyAdminDetails, setPropertyAdminDetails] = useState({});
  const [propertyDetails, setPropertyDetails] = useState({});
  const [tenantDetails, setTenantDetails] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = React.useState({
    propertyId: "",
    userEmail: "",
  });
  const steps = [
    ["Find Your Property", "Property Details", "Payment"],
    ["Find Your Property", "Register Lease"],
  ];
  const [currentPath, setCurrentPath] = useState(steps[0]);
  const [currentStep, setCurrentStep] = useState(0);
  const [newTenant, setNewTenant] = useState(false);
  const params = useParams();

  const fetchPropertyAdmin = async (name) => {
    try {
      setLoading(true);
      const response = await getPropertyAdminByName(name);
      setPropertyAdminDetails(response);
    } catch (error) {
      setError(true);
      setLoading(false);
      console.log("Error fetching Property Admin:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendOTP = async (formData) => {
    try {
      const updatedFormData = { ...formData, propertyAdminId: propertyAdminDetails?.admin?.id };
      const response = await sendPaymentOTP(updatedFormData);
      setPropertyDetails(response.propertyDetails);
      setOpenOtpModal(true);
      if (!response.lease_exists) {
        setNewTenant(true);
        setCurrentPath(steps[1]);
      }
    } catch (error) {
      console.log("Error sending OTP:", error);
      toast.error(error.error);
    }
  };

  const handleOtpVerify = async () => {
    if (!otpCode) {
      return toast.error("Please enter the OTP code");
    }
    try {
      const data = { email: formData.userEmail, otp: Number(otpCode) };
      const response = await verifyPaymentOTP(data);
      setTenantDetails(response.data.user);
      setOpenOtpModal(false);
      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.log("Error verifying OTP:", error);
      toast.error(error.message);
    }
  };

  const handleRegisterLease = async (data) => {
    try {
      await createPropertyLease(propertyDetails?.id, data);
      setLeaseRegisterModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleContactSupport = () => {
    window.open("https://api.whatsapp.com/send?phone=971544551386", "_blank");
  };

  React.useEffect(() => {
    const name = params.shortName || shortName;
    if (name) fetchPropertyAdmin(name);
    setCompanyPage(true);
  }, [params.shortName, shortName]);

  if (loading) {
    return <Typography variant="h5">Loading...</Typography>;
  }
  if (error) {
    return (
      <Typography variant="h5">
        Company details are not Registered with us
      </Typography>
    );
  }
  return (
    <Box
      bgcolor="#F5F7FA"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
    >
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={5}
        py={2}
        bgcolor="white"
        borderBottom="1px solid #E0E0E0"
      >
        <Typography variant="h6" fontWeight="bold">
          {propertyAdminDetails?.admin?.name}
        </Typography>
        <Button
          onClick={handleContactSupport}
          variant="outlined"
          style={{
            borderColor: "#202020",
            color: "#202020",
            textTransform: "none",
          }}
        >
          <Box display="flex" alignItems="center" gap="8px">
            <Icon path={Icons.chat} />
            Contact Support
          </Box>
        </Button>
      </Box>

      {/* Stepper */}
      <Stepper steps={currentPath} currentStep={currentStep} />

      {/* Form Section */}
      {currentStep === 0 && (
        <PropertyInfo
          formData={formData}
          setFormData={setFormData}
          handleNextClick={handleSendOTP}
        />
      )}

      {currentStep === 1 && !newTenant && (
        <TenantPayment
          tenantDetails={tenantDetails}
          propertyDetails={propertyDetails}
        />
      )}

      {currentStep === 1 && newTenant && (
        <TenantRegister
          tenantDetails={tenantDetails}
          propertyDetails={propertyDetails}
          handleRegisterLease={handleRegisterLease}
        />
      )}

      {/* Footer */}
      <Box
        mt="auto"
        py={2}
        px={5}
        borderTop="1px solid #E0E0E0"
        bgcolor="white"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box height={36} mr={2}>
          <Logo />
        </Box>
        <Typography variant="body2" color="textSecondary">
          Terms & Conditions | Privacy Policy
        </Typography>
      </Box>

      {/* OTP Verification Modal */}
      <Dialog
        maxWidth="xs"
        open={openOtpModal}
        onClose={() => {
          setOpenOtpModal(false);
          setNewTenant(false);
          setCurrentPath(steps[0]);
        }}
        aria-labelledby="otp-verification-dialog"
      >
        <DialogTitle id="otp-verification-dialog">Verification</DialogTitle>
        <DialogContent>
          <Typography>
            We sent a 6-digit code to your email. Enter the code below to
            confirm:
          </Typography>
          <TextField
            margin="normal"
            variant="outlined"
            type="number"
            label="Enter OTP"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
            fullWidth
          />
          <Box textAlign="center">
            <Button
              variant="contained"
              style={{
                margin: "0px auto",
                backgroundColor: "#01D4A1",
                color: "#FFF",
                fontWeight: "bold",
                borderRadius: "48px",
                padding: "8px 30px",
              }}
              onClick={handleOtpVerify}
            >
              Verify
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Lease Registration Modal */}
      <Dialog
        maxWidth="xs"
        open={leaseRegisterModal}
        onClose={() => {
          setLeaseRegisterModal(false);
        }}
        aria-labelledby="otp-verification-dialog"
      >
        <DialogTitle id="otp-verification-dialog">Congratulations</DialogTitle>
        <DialogContent>
          <Typography>
            Your lease is now under approval with your property manager and will
            be ready for online rent payments within 1-2 working days.
          </Typography>
          <Box textAlign="center">
            <Button
              variant="contained"
              style={{
                margin: "0px auto",
                backgroundColor: "#01D4A1",
                color: "#FFF",
                fontWeight: "bold",
                borderRadius: "48px",
                padding: "8px 30px",
              }}
              onClick={() => {
                setLeaseRegisterModal(false);
                window.location.reload();
              }}
            >
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
