import {
  Box,
  Button,
  InputAdornment,
  Select,
  TextField,
  Typography,
  MenuItem,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import * as Yup from "yup";

const areaCodes = ["50", "52", "54", "55", "56", "58"];
export default function TenantRegister({
  handleRegisterLease,
  propertyDetails,
  tenantDetails,
}) {
  const validationSchema = Yup.object().shape({
    name: Yup.string("Please enter a valid name").required("Name is required"),
    email_id: Yup.string()
      .email("Please enter a valid email")
      .required("Email is required"),
    mobileNumber: Yup.string("Please enter a valid mobile number")
      .matches(/^\d{7}$/, "Mobile number must be exactly 7 digits")
      .required("Mobile Number is required"),
    plotNumber: Yup.string().required("Apartment Number is required"),
  });
  const [errors, setErrors] = React.useState({});
  const [formData, setFormData] = React.useState({
    name: tenantDetails?.name || "",
    email_id: tenantDetails?.email_id || "",
    mobileNumber: tenantDetails?.mobileNumber?.slice(-7) || "",
    areaCode:
      tenantDetails?.mobileNumber?.length >= 9 &&
      areaCodes.includes(tenantDetails?.mobileNumber?.slice(0, 3))
        ? tenantDetails?.mobileNumber?.slice(0, 3)
        : "50",
    plotNumber: "",
  });

  const handleChange = (e) => {
    setErrors((prev) => ({ ...prev, [e.target.name]: null }));
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const data = {
        propertyId: propertyDetails?.id,
        userId: tenantDetails?.id,
        myHome: formData?.name + " " + formData?.plotNumber,
        usage: "Apartment",
        plotNumber: formData?.plotNumber,
        subType: "",
        contractAmmount: 0,
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().add(12, "months").format("YYYY-MM-DD"),
        noOfInvoices: 1,
        rentAgreement: "",
        paymentDates: [],
        payingRentForDate: [],
        name: formData?.name,
        email_id: formData?.email_id,
        mobileNumber: formData?.areaCode+formData?.mobileNumber,
      };
      handleRegisterLease(data);
    } catch (error) {
      const validationErrors = {};
      error?.inner?.forEach((e) => {
        validationErrors[e.path] = e.message;
      });
      setErrors(validationErrors);
    }
  };
  return (
    <Box
      mx="auto"
      mt={4}
      p={4}
      width="600px"
      bgcolor="#FFF"
      borderRadius="10px"
      border="1px solid #01D4A1"
    >
      <Typography variant="h5" color="#092B4F" style={{ fontWeight: "bold" }}>
        Tenant Details
      </Typography>
      <Box
        border="1px solid #01D4A1"
        borderRadius={5}
        width="100%"
        px={3}
        py={1}
      >
        <Typography variant="body1" color="#092B4F" fontWeight="bold">
          Lessor:
        </Typography>
        <Typography variant="body2" color="#092B4F">
          {propertyDetails?.propertyLessor?.lessorName}
        </Typography>
        <Typography variant="body2" color="#092B4F">
          {propertyDetails?.buildingName}
        </Typography>
        <Typography variant="body2" color="#092B4F">
          {propertyDetails?.landArea ? `${propertyDetails?.landArea}, ` : ""}
          {propertyDetails?.city}
        </Typography>
      </Box>

      <Box
        display="flex"
        gridColumnGap="14px"
        justifyContent="space-between"
        flexWrap="wrap"
        gridRowGap="14px"
        my={2}
      >
        <TextField
          label="Name"
          variant="outlined"
          name="name"
          value={formData?.name}
          style={{ width: "48%" }}
          onChange={handleChange}
          error={errors.name}
          helperText={errors.name}
        />
        <TextField
          disabled
          label="Email Address"
          variant="outlined"
          name="email_id"
          value={formData?.email_id}
          style={{ width: "48%" }}
          onChange={handleChange}
          error={errors.email_id}
          helperText={errors.email_id}
        />
        <TextField
          variant="outlined"
          type="number"
          style={{ width: "48%" }}
          label="Mobile Number"
          value={formData.mobileNumber}
          onChange={handleChange}
          name="mobileNumber"
          error={Boolean(errors.mobileNumber)}
          helperText={errors.mobileNumber}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography style={{ marginRight: 8 }}>+971</Typography>
                <Select name="areaCode" value={"50"} onChange={handleChange}>
                  {areaCodes.map((code) => (
                    <MenuItem key={code} value={code}>
                      {code}
                    </MenuItem>
                  ))}
                </Select>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="Apartment Number"
          variant="outlined"
          name="plotNumber"
          value={formData?.plotNumber}
          style={{ width: "48%" }}
          onChange={handleChange}
          error={errors.plotNumber}
          helperText={errors.plotNumber}
        />
      </Box>
      <Box textAlign="center">
        <Button
          variant="contained"
          style={{
            margin: "0px auto",
            backgroundColor: "#01D4A1",
            color: "#FFF",
            fontWeight: "bold",
            borderRadius: "48px",
            padding: "8px 30px",
          }}
          onClick={handleSubmit}
        >
          Register Your Lease
        </Button>
      </Box>
    </Box>
  );
}
