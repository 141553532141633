/* cSpell:disable */

const config = {
  env: process.env.NODE_ENV,

  GOOGLE_MAP_KEY: process.env.REACT_APP_GOOGLE_MAP_KEY,

  API_URL: process.env.REACT_APP_API_URL,
  WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL,

  /* CRYPTO ENCRYPT/DECRYPT CONST */
  ENCRYPTION_KEY: "15a1fad0bc7e83174b65176008cad159",
};

export default config;
