import { Box, Grid } from "@material-ui/core";
import { Loader } from "../components";
import {
  arrayToCommaString,
  calculateInvoiceDue,
  encryptData,
  formatCurrency,
  getPaymentUrl,
  getProcessingFee,
  roundValue,
  stringifyPayfortObject,
} from "../utils";
import { Link, useParams } from "react-router-dom";
import Icons from "../constants/icons";
import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/logo.png";
import { get, getSettings } from "../services/api";
import moment from "moment";
import "./MakeInvoicePayment.css";
import { PROCESSING_FEE_TYPES } from "../constants";

const MakeInvoicePayment = () => {
  const params = useParams();
  const [invoice, setInvoice] = useState([]);
  const [amountDue, setAmountDue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState();
  const [leasedId, setLeasedId] = useState();
  const [settings, setSettings] = useState(null);
  const [processingFee, setProcessingFee] = useState(0);
  // const [totalToPay, setTotalToPay] = useState(0);
  const totalToPay = useRef(0);
  const tabbyFee = useRef(0);
  const paymentMethod = useRef("card");
  const [allowTabby, enableTabbyPayment] = useState(false);
  // const [paymentMethod, setpaymentMethod] = useState("card");

  useEffect(() => {
    getInvoiceDetails();
  }, [params]);

  const handlePaymentAmount = (amount, setting = settings) => {
    console.log("Amount ", amount);
    let mainAmount = amount.toString().replaceAll(",", "");
    /* TABBYOFF*/
    if (mainAmount <= 5000) {
      console.log("Enabling");
      enableTabbyPayment(true);
    } else {
      enableTabbyPayment(false);
      paymentMethod.current = "card";
    }
    let pay = amount;
    if (isNaN(pay)) {
      pay = pay.replaceAll(",", "");
    }
    let total = Number(pay);
    let fee = 0;
    let vatPercent = 0;

    /* TABBYOFF*/
    if (paymentMethod.current === "tabby" && mainAmount < 5000) {
      fee = pay * 6 * 0.01;
      tabbyFee.current = fee;
    } else if (setting?.processingFeesType === PROCESSING_FEE_TYPES[0]) {
      fee = setting?.processingFees;
    } else {
      fee = pay * setting?.processingFees * 0.01;
    }
    console.log("FEE __", roundValue(fee));
    fee = roundValue(fee);

    console.log("FEE ", fee, roundValue(fee * 0.05), total);
    // Add fee + 5% VAT
    if (vatPercent === 0) {
      total = Math.ceil(total + fee);
    } else {
      total = Math.ceil(total + fee + roundValue(fee * vatPercent * 0.01));
    }
    // if(total > 30000){
    //   setTotalPayError('Maximum payment per transaction is 30,000 AED. Pls try again.');
    // } else {
    //     setTotalPayError('');
    // }
    setProcessingFee(fee);
    totalToPay.current = total;
  };

  const handlePaymentMethodChange = (e) => {
    paymentMethod.current = e.target.value;
    handlePaymentAmount(amountDue);
  };

  const getInvoiceDetails = async () => {
    try {
      let { data: settings } = await getSettings();
      setSettings(settings);
      console.log("runinnng");
      setIsLoading(true);
      const result = await get.getInvoicePaymentDetails(params);
      setInvoice(result.data);
      let pendingAmount = calculateInvoiceDue(
        result.data?.invoiceDetail?.amount,
        result.data?.invoiceDetail?.payments
      );
      setAmountDue(roundValue(pendingAmount));
      console.log("result.data", result.data?.tenancyContactDetails?.status);
      setLeasedId(result?.data?.tenancyContactDetails?.id);
      setStatus(result?.data?.tenancyContactDetails?.status);
      handlePaymentAmount(pendingAmount, settings);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const makeInvoicePayment = () => {
    const addedDetails = {
      userId: invoice?.invoiceDetail?.propertyLessedUnit?.userId,
      invoiceId: invoice?.invoiceDetail?.id,
      processingFee: processingFee,
      originalAmount: amountDue,
      vatApplied: 0,
      paymentMode: paymentMethod.current,
    };
    console.log("addedDetails ", addedDetails);

    const paymentObject = {
      invoiceId: addedDetails.invoiceId,
      amount: totalToPay.current,
      extra: stringifyPayfortObject(addedDetails),
    };

    const data = encryptData(JSON.stringify(paymentObject));
    const payURL = getPaymentUrl({ data });

    window.location.href = payURL;
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        style={{
          width: 840,
          maxWidth: "calc(100% - 120px)",
          margin: "0px auto",
          border: "1px solid #e0dede",
          marginTop: "60px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 16,
          }}
        >
          <div style={{ fontSize: 22 }}>
            Invoice from{" "}
            <strong>
              {invoice?.invoiceDetail?.property?.propertyAdmin?.name}
            </strong>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img style={{ width: 64, height: 64 }} src={logo} alt={"Renters"} />
            <span style={{ fontWeight: "bold" }}>Powered by Renters</span>
          </div>
        </div>
        {isLoading ? (
          <Loader absolute />
        ) : (
          <>
            <Box display="flex" className="invoice-container">
              <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                  <div className="inv-details-container">
                    <div className="inv-details-text">Invoice Details</div>
                    <div className="details-wrapper">
                        <div className="text">{invoice?.invoiceDetail?.propertyLessedUnit?.user?.name}</div>
                      <div className="due-on-wrapper">
                        <div className="title">Due on</div>
                        <div className="text">
                          {moment(invoice?.invoiceDetail?.dueDate).format(
                            "Do MMM, YYYY"
                          )}
                        </div>
                      </div>
                      <div className="due-on-wrapper">
                        <div className="title">Due For</div>
                        <div>
                          <div>Rent Payment</div>
                          <div>
                            {arrayToCommaString([
                              invoice?.invoiceDetail?.propertyLessedUnit
                                ?.myHome,
                              invoice?.invoiceDetail?.property?.buildingName,
                              invoice?.invoiceDetail?.propertyLessedUnit
                                ?.plotNumber,
                              invoice?.invoiceDetail?.propertyLessedUnit
                                ?.subType,
                            ])}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={7}>
                  <div className="field_wrapper">
                    <div className="input-wrapper">
                      <label htmlFor="amountToPay">Amount to Pay:</label>
                      <div className="amount-wrap">
                        <input
                          type="text"
                          className="input-box"
                          id="amountToPay"
                          value={`${formatCurrency(Math.ceil(amountDue))}`}
                          disabled={true}
                        />
                        <span>
                          Processing Fee:{" "}
                          {paymentMethod.current === "tabby"
                            ? `${formatCurrency(Math.ceil(tabbyFee.current))}`
                            : getProcessingFee(settings)}
                        </span>
                      </div>
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="totalToPay">Total Payment:</label>
                      <input
                        type="text"
                        disabled={true}
                        className="input-box"
                        id="totalToPay"
                        value={formatCurrency(totalToPay.current)}
                      />
                    </div>
                    <div className="radio-group-container">
                      <label className="radio-group-label">
                        How would you like to make the payment?
                      </label>
                      <div className="radio-container">
                        <label className="radio-label">
                          <input
                            type="radio"
                            value="card"
                            className="radio-input"
                            checked={paymentMethod.current === "card"}
                            onChange={handlePaymentMethodChange}
                          />
                          Card Payment
                        </label>

                        <label className="radio-label">
                          <input
                            type="radio"
                            value="tabby"
                            disabled={!allowTabby}
                            className="radio-input"
                            checked={paymentMethod.current === "tabby"}
                            onChange={handlePaymentMethodChange}
                          />
                          Tabby Pay
                        </label>
                      </div>
                    </div>
                    <div className="submit-btn">
                      <button
                        type="submit"
                        disabled={totalToPay.current <= 0}
                        className="submit-button"
                        onClick={makeInvoicePayment}
                      >
                        MAKE PAYMENT
                      </button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};

export default MakeInvoicePayment;
